<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据关联信息显示tip(文字拼接形式)
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <el-tooltip placement="right" effect="light">
      <!-- 总框内容 -->
      <div slot="content" class="billsTipAll" v-loading="isGetEndData" element-loading-text="数据加载中,请稍候..."
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <!-- 统一显示方式 -->
        <div v-if="!sepcifactionTip.includes(originBillStateType)">
          <!-- 头部统计数据 -->
          <ul class="headBox">
            <li class="headItem">
              <span class="title"> {{relevanceBillsData.totalName}}:</span>
              <span class="value"> {{relevanceBillsData.obillTotal}}</span>
            </li>
            <li class="headItem">
              <span class="title"> {{relevanceBillsData.successName}}:</span>
              <span class="value color_success"> {{relevanceBillsData.obillSuccessTotal}}</span>
            </li>
            <li class="headItem">
              <span class="title"> {{relevanceBillsData.waitName}}:</span>
              <span class="value color_warning"> {{relevanceBillsData.obillWaitTotal}}</span>
            </li>
          </ul>
          <!-- 循环出的单据列表信息 -->
          <ul class="billListBox">
            <li v-for="(elem,index) in relevanceBillsData.billingOtherRelvanceList" class="billListItem" title="点击查看详情"
              @click="getRelvanceDetail(elem,elem.sign,elem.billsClassify)">
              <!-- 序号 -->
              <span style="font-size: 14px;">※</span>
              <!-- 单据状态(对账状态) -->
              <div v-if="['0.2','3.3'].includes(originBillStateType) && [4,5].includes(originBillState)">
                (<span v-for="elemJ in costCheckState" v-if="elem.isSubmit== elemJ.value"
                  :class="'color_'+elemJ.class">{{elemJ.name}}</span>)
              </div>
              <!-- 单据状态(收付款状态) -->
              <div v-else>
                (<span v-for="elemJ in billStateList" v-if="elem.isSubmit== elemJ.value"
                  :class="'color_'+elemJ.class">{{elemJ.name}}</span>)
              </div>
              <!-- 单据日期 -->
              <span class="billsDate">{{elem.billsDate}}</span>
              <!-- 操作名称 -->
              <span> {{elem.operateName}} </span>
              <!-- 关联单据处理的数量 -->
              <span class="color_success"> {{elem.totalNum}} </span>
              <!-- 单据类型 -->
              <span> {{elem.billingTypeInfo.bills_type}}</span>
              <!-- 连接符号 -->
              <span>号: </span>
              <!-- 单据编号 -->
              <span class="billsNo">{{elem.billsNo}}</span>
            </li>
          </ul>
        </div>

        <!-- 特殊显示方式 -->
        <div v-else>
          <!-- 询价单 -->
          <div v-if="originBillStateType == '3.2'">
            <!-- 头部统计数据 -->
            <ul class="headBox">
              <li class="headItem">
                <span class="title"> {{relevanceBillsData.billingOtherRelvanceList.length}}个询价单 </span>
                <span>最优价供应商是: </span>
                <span v-if="relevanceBillsData.billingOtherRelvanceList.length>0">
                  {{relevanceBillsData.billingOtherRelvanceList[0].supplierName}}
                </span>
              </li>
            </ul>
            <!-- 循环出的单据列表信息 -->
            <ul class="billListBox">
              <li v-for="(elem,index) in relevanceBillsData.billingOtherRelvanceList" class="billListItem">
                <!-- 序号 -->
                <span style="font-size: 14px;">※</span>
                <!-- 审核状态 -->
                (<span v-for="elemJ in billStateList" v-if="elem.isSubmit== elemJ.value"
                  :class="'color_'+elemJ.class">{{elemJ.name}}</span>)
                <!-- 单据日期 -->
                <span class="billsDate">{{elem.billsDate}}</span>
                <!-- 操作名称 -->
                <span> {{elem.operateName}} </span>
                <!-- 关联单据处理的数量 -->
                <span class="color_success"> {{elem.totalNum}} </span>
                <!-- 单据类型 -->
                <span> {{elem.billingTypeInfo.bills_type}}</span>
                <!-- 连接符号 -->
                <span>号: </span>
                <!-- 单据编号 -->
                <span class="billsNo" title="点击查看详情"
                  @click="getRelvanceDetail(elem,elem.sign,elem.billsClassify)">{{elem.billsNo}}</span>
              </li>
            </ul>
          </div>

          <!-- 退货单 -->
          <div v-if="originBillStateType == '0.6' || originBillStateType == '3.6'">
            <!-- 头部统计数据 -->
            <ul class="headBox">
              <li class="headItem">
                <span class="title"> {{relevanceBillsData.totalName}}:</span>
                <span class="value"> {{relevanceBillsData.obillTotal}}</span>
              </li>
              <li class="headItem">
                <span class="title"> {{relevanceBillsData.successName}}:</span>
                <span class="value color_success"> {{relevanceBillsData.obillSuccessTotal}}</span>
              </li>
              <!-- <li class="headItem">
                <span class="title"> 有退款</span>
              </li> -->
            </ul>
            <!-- 循环出的单据列表信息 -->
            <ul class="billListBox">
              <li v-for="(elem,index) in relevanceBillsData.billingOtherRelvanceList" class="billListItem">
                <!-- 序号 -->
                <span style="font-size: 14px;">※</span>
                <!-- 审核状态 -->
                (<span v-for="elemJ in billStateList" v-if="elem.isSubmit== elemJ.value"
                  :class="'color_'+elemJ.class">{{elemJ.name}}</span>)
                <!-- 单据日期 -->
                <span class="billsDate">{{elem.billsDate}}</span>
                <!-- 操作名称 -->
                <span> {{elem.operateName}} </span>
                <!-- 关联单据处理的数量 -->
                <span class="color_success"> {{elem.totalNum}} </span>
                <!-- 单据类型 -->
                <span> {{elem.billingTypeInfo.bills_type}}</span>
                <!-- 连接符号 -->
                <span>号: </span>
                <!-- 单据编号 -->
                <span class="billsNo" title="点击查看详情"
                  @click="getRelvanceDetail(elem,elem.sign,elem.billsClassify)">{{elem.billsNo}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- **************************根据不同模块显示单据状态************************** -->
      <!-- 统一显示方式 -->
      <div v-if="!sepcifactionState.includes(originBillStateType)">
        <el-button v-for="(elem,index) in stateData" :type="elem.class" size="mini" :style="{background:elem.color,border:'none'}"
          v-if="originBillState == elem.value" @mouseenter.native="startGetBillsRelvance(originBillState)"
          @mouseleave.native="endGetBillsRelvance()">
          {{elem.name}}
        </el-button>
      </div>

      <!-- 特殊显示方式 -->
      <div v-else>
        <!-- 询价 -->
        <div v-if="originBillStateType == '3.2'">
          <el-button type="danger" size="mini" v-if="relevanceBillsData.billingOtherRelvanceList.length == 0"
            @mouseenter.native="startGetBillsRelvance()" @mouseleave.native="endGetBillsRelvance()">无询价
          </el-button>
          <el-button type="success" size="mini" v-if="relevanceBillsData.billingOtherRelvanceList.length > 0"
            @mouseenter.native="startGetBillsRelvance()" @mouseleave.native="endGetBillsRelvance()">有询价
          </el-button>
        </div>
      </div>

    </el-tooltip>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入接口
  import sellApi from '@/network/sell/bills.js'; //销售模块
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块
  import stockApi from '@/network/stock/stock.js'; //库存模块
  import mesApi from '@/network/production/production.js'; //生产模块相关接口
  import costApi from '@/network/cost/account.js'; //资金模块

  export default {
    name: 'relvanceBillTipsStrip',
    props: {
      //单据id
      originBillId: {
        type: Number,
        default: -1
      },
      //当前状态
      originBillState: {
        type: Number,
        default: -1
      },
      // 状态类型(格式(sign.模块下状态数量顺序))
      //销售:0.0:单据状态 0.1:生成状态 0.2:收款状态  0.3:发票状态 0.4:出库状态  0.5:生产状态 0.6:退货状态 0.7:采购状态
      //生产:1.0:单据状态 1.1:领料状态 1.2:入库状态  1.3:付款状态 1.4:采购状态
      //库存:2.0:单据状态 2.1:质检状态 2.2:退款状态  2.3退货状态  2.4:发票状态  2.5:归还状态
      //采购:3.0:单据状态 3.1:生成状态 3.2:询价状态  3.3:付款状态 3.4:入库状态  3.5:发票状态 3.6:退货状态
      //收付款:5.0:单据状态 ,
      //报销:6.0:单据状态 6.1:结算状态
      //生产执行：7.1单据状态 7.2 工序状态 7.3领料状态 7.4付款状态
      //发票 8.0:单据状态
      //对账 9.0 单据状态 9.1:发票状态
      originBillStateType: {
        type: String,
        default: ''
      },
      //需要使用的状态名称
      stateKeyName: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        danger: 'danger',
        //组件所需数据
        stateData: [], //状态数据
        relevanceBillsData: {
          billingOtherRelvanceList: [], //关联单据列表
        }, // 关联单据信息
        sepcifactionState: ['3.2'], //特殊显示的状态(询价)
        sepcifactionTip: ['0.6', '3.2', '3.6'], //特殊显示的提示框(销售退货,询价,采购退货)

        // 控制开关
        isGetEndData: true, //开始查询单据信息(为0时不查询 为1时查询)

        //定时器相关
        timerGetBills: null, //获取单据关联单据定时器

        //辅助数据
        loading: '', //loading框
      }
    },
    mounted() {
      //获取状态数据
      this.getStateData();
    },
    computed: {
      ...mapState({
        //公共相关
        billStateList: state => state.bill_state.billStateList, //单据状态
        generateState: state => state.bill_state.generateState, //生成状态

        //生产相关
        mesState: state => state.bill_state.mesState, //生产状态
        materialStateGet: state => state.bill_state.materialStateGet, //领料状态
        returnStateReturn: state => state.bill_state.returnStateReturn, //退料状态

        //库存相关
        depotStateIn: state => state.bill_state.depotStateIn, //入库状态
        depotStateOut: state => state.bill_state.depotStateOut, //出库状态
        returnState: state => state.bill_state.returnState, //退货状态
        qualityState: state => state.bill_state.qualityState, //质检状态
        backState: state => state.bill_state.backState, //归还状态

        //采购相关
        purchaseState: state => state.bill_state.purchaseState, //采购状态
        enquiryState: state => state.bill_state.enquiryState, //询价状态

        //收付款相关
        costInStateIn: state => state.bill_state.costInStateIn, //收款状态
        costInStateOut: state => state.bill_state.costInStateOut, //付款状态
        costReturnStateOut: state => state.bill_state.costReturnStateOut, //退款状态

        //报销相关
        expenseState: state => state.bill_state.expenseState, //结算状态

        //发票相关
        invoiceState: state => state.bill_state.invoiceState, //发票状态

        //对账相关
        costCheckState: state => state.bill_state.costCheckState, //对账状态
      })
    },
    methods: {
      /* 获取单据状态 */
      getStateData() {
        //获取单据状态数据
        this.stateData = this. [this.stateKeyName];
      },

      /* 鼠标移入时延迟获取单据关联信息 */
      startGetBillsRelvance(state) {
        //清除数据(设置默认值)
        this.relevanceBillsData = {
          totalName: '总量',
          obillTotal: 0,
          successName: '已处理',
          obillSuccessTotal: 0,
          waitName: '待处理',
          obillWaitTotal: 0,
          billingOtherRelvanceList: []
        }; //关联单据数据
        //设置数据请求中(加载loading框)
        this.isGetEndData = true;
        //延迟获取数据
        this.timerGetBills = setTimeout(() => {
          //定义请求数据
          let getData = {
            id: this.originBillId
          }
          //判断需要发送请求的模块
          switch (this.originBillStateType) {
            //销售模块
            case '0.0': //单据状态
              sellApi.findSaleOriginbill(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '0.1': //生成状态
              sellApi.findOrderMain(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '0.2': //收款状态
              //判断是否为对账状态
              if ([4, 5].includes(state)) { //对账中,已对账
                sellApi.findOrderCostCheckIn(getData).then(res => {
                  if (res.code == 200) {
                    //获取数据
                    this.relevanceBillsData = res.data;
                    //设置数据已获取完成
                    this.isGetEndData = false;
                  } else {
                    this.$message({
                      type: "error",
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              } else { //收款状态
                sellApi.findOrderCostMainIn(getData).then(res => {
                  if (res.code == 200) {
                    //获取数据
                    this.relevanceBillsData = res.data;
                    //设置数据已获取完成
                    this.isGetEndData = false;
                  } else {
                    this.$message({
                      type: "error",
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              }
              break;
            case '0.3': //发票状态
              sellApi.findOrderInvoiceMain(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '0.4': //出库状态
              sellApi.findOrderDepotMainOut(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '0.5': //生产状态
              sellApi.findOrderMesMain(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '0.6': //退货状态
              sellApi.findOrderDepotMainRetun(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '0.7': //采购状态
              sellApi.findOrderPurchaseMain(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
              // 生产模块
            case '1.0': //单据状态
              mesApi.findMesOriginBill(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '1.1': //领料状态
              mesApi.findDepotMainGet(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '1.2': //入库状态
              mesApi.findDepotMainIn(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '1.3': //付款状态
              mesApi.findCostMainOut(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '1.4': //采购状态
              mesApi.findPurchaseMain(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
              // 库存模块
            case '2.0': //单据状态
              stockApi.findDepotOriginBill(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '2.1': //质检状态
              stockApi.findDepotMainQuality(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '2.2': //退款状态
              stockApi.findCostMainOut(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '2.3': //退货状态
              stockApi.findDepotMainOutIn(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '2.4': //发票状态
              stockApi.findCostInvoice(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '2.5': //归还状态
              stockApi.findDepotMainOutIn(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
              //采购模块
            case '3.0': //单据状态
              purchaseApi.findPurchaseOriginBill(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '3.1': //生成状态
              purchaseApi.findPurchaseOrderMain(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '3.2': //询价状态
              purchaseApi.findPurchaseEnquiryMain(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '3.3': //付款状态
              if ([4, 5].includes(state)) { //对账中,已对账
                purchaseApi.findOrderCostCheckOut(getData).then(res => {
                  if (res.code == 200) {
                    //获取数据
                    this.relevanceBillsData = res.data;
                    //设置数据已获取完成
                    this.isGetEndData = false;
                  } else {
                    this.$message({
                      type: "error",
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              } else {
                purchaseApi.findPurchaseCostMain(getData).then(res => {
                  if (res.code == 200) {
                    //获取数据
                    this.relevanceBillsData = res.data;
                    //设置数据已获取完成
                    this.isGetEndData = false;
                  } else {
                    this.$message({
                      type: "error",
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              }
              break;
            case '3.4': //入库状态
              purchaseApi.findPurchaseDepotMainIn(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '3.5': //发票状态
              purchaseApi.findPurchaseInvoiceMain(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            case '3.6': //退货状态
              purchaseApi.findPurchaseDepotMainReturn(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
            
              //报销模块
            case '6.1': //结算状态
              costApi.findExpenseCostMain(getData).then(res => {
                if (res.code == 200) {
                  //获取数据
                  this.relevanceBillsData = res.data;
                  //设置数据已获取完成
                  this.isGetEndData = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              break;
              //工序模块
              case '7.3': //领料状态
                mesApi.findDepotMainProcessGet(getData).then(res => {
                  if (res.code == 200) {
                    //获取数据
                    this.relevanceBillsData = res.data;
                    //设置数据已获取完成
                    this.isGetEndData = false;
                  } else {
                    this.$message({
                      type: "error",
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
                break;
              //对账模块
            case '9.1': //发票状态
              // costApi.findExpenseCostMain(getData).then(res => {
              //   if (res.code == 200) {
              //     //获取数据
              //     this.relevanceBillsData = res.data;
              //     //设置数据已获取完成
              //     this.isGetEndData = false;
              //   } else {
              //     this.$message({
              //       type: "error",
              //       message: res.message,
              //       duration: this.elDuration
              //     })
              //   }
              // })
              break;
          }
        }, 100);
      },

      /* 鼠标移出时清除定时器且清空数据 */
      endGetBillsRelvance() {
        //清除定时器
        clearTimeout(this.timerGetBills);
        //数据本次获取数据结束
        this.isGetEndData = false;
      },

      /* 查看单据详情接口 */
      getRelvanceDetail(data, sign, classify) {
        if (sign == 9) {
          this.$message({
            type: "warning",
            message: "对账单暂不支持预览",
            duration: this.elDuration
          })
          return;
        }
        this.$emit("getRelvanceDetail", data, sign, classify)
      },
    }
  }
</script>

<style lang="less">
  // 提示信息总框
  .billsTipAll {
    padding: 10px;
    box-shadow: 6px 6px 4px 0 rgb(0 0 0 / 20%), 0 0 3px 0 rgb(0 0 0 / 30%);

    // 头部内容
    .headBox {
      min-width: 340px;
      // min-height: 60px;
      margin: 10px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      font-weight: bold;

      //单个数据
      .headItem {
        padding: 0 10px;
      }
    }

    // 单据列表数据
    .billListBox {

      // 单条数据
      .billListItem {
        font-size: 14px;
        padding: 5px 0;
        cursor: pointer;
        display: flex;

        // 日期样式
        .billsDate {
          font-weight: bold;
        }

        //单据编号
        .billsNo {
          color: @theme;
          cursor: pointer;
        }

        // .billsNo:hover {
        //   color: #0037ff;
        // }
      }

      .billListItem:hover {
        color: #0037ff !important;
      }
    }
  }
</style>
