<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：驳回信息显示tip
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <el-tooltip placement="right" effect="light">
      <div slot="content">
        <div class="billsList_RelvanceTipChunk searchClassfiy">
          <!-- 单据内容框 -->
          <ul class="billsBox">
            <!-- 无数据时显示 -->
            <li class="billsItem" v-if="rejectData.length == 0">
              <ul class="ItemBox">
                <!-- 公共参数值 -->
                <li class="itemLi">
                  <span class="title">驳回人:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>
                <li class="itemLi">
                  <span class="title">驳回时间:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>
                <li class="itemLi">
                  <span class="title">驳回原因:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>
              </ul>
            </li>
            <!-- 循环显示多个内容 -->
            <li class="billsItem" v-for="(elem,index) in rejectData" v-else>
              <ul class="ItemBox">
                <li class="itemLi ItemTitle">您的审核被驳回</li>
                <li class="itemLi">
                  <span class="title">驳回人:</span>
                  <span class="value">{{elem.userName}}</span>
                </li>
                <li class="itemLi">
                  <span class="title">驳回时间:</span>
                  <span class="value">{{elem.addTime}}</span>
                </li>
                <li class="itemLi">
                  <span class="title">驳回原因:</span>
                  <span class="value valueContent">{{elem.content}}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <!-- 单据状态 -->
      <div>
        <el-button v-for="(elem,index) in billStateList" :type="elem.class" size="mini" v-if="originBillState == elem.value"
          @mouseenter.native="startGetBillsRelvance()" @mouseleave.native="endGetBillsRelvance()">{{elem.name}}
        </el-button>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入接口
  import billsComm from '@/network/billsComm/billsComm.js'; //单据公共模块

  export default{
    name:'rejectBillTips',
    props: {
      //单据id
      originBillId: {
        type: Number,
        default: -1
      },
      //当前状态
      originBillState: {
        type: Number,
        default: -1
      },
      // 使用的模块分类
      billsSign:{
        type: Number,
        default: -1
      },
    },
    data(){
      return{
        //组件数据
        rejectData:[],//驳回数据数组

        // 控制开关
        isGetEndData:true,//开始查询单据信息(为0时不查询 为1时查询)

        //定时器相关
        timerGetBills:null,//获取单据关联单据定时器
      }
    },
    computed: {
      ...mapState({
        //公共相关
        billStateList: state => state.bill_state.billStateList, //单据状态
      })
    },
    methods:{
      /* 鼠标移入时延迟获取驳回信息 */
      startGetBillsRelvance(){
        //设置数据请求中(加载loading框)
        this.isGetEndData = true;
        //延迟获取数据
        this.timerGetBills = setTimeout(() => {
          //定义请求数据
          let getData = {
            id:this.originBillId,//单据id
            sign:this.billsSign,//所属模块
            operateType:4,//操作方式(驳回)
          }
          //发送请求
          billsComm.findBillLogByOperateType(getData).then(res=>{
            if(res.code == 200){
              //获取数据
              this.rejectData = res.data;
              //设置数据已获取完成
              this.isGetEndData = true;
            }else{
              this.$message({
                type:error,
                message:res.message,
                duration:this.elDuration
              })
            }
          })
        },300)
      },

      /* 鼠标移出时清除定时器且清空数据 */
      endGetBillsRelvance() {
        //清除定时器
        clearTimeout(this.timerGetBills);
        //数据本次获取数据结束
        this.isGetEndData = false;
      },
    }
  }
</script>

<style>
</style>
